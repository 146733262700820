import React, {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Typography,
  Row,
  Divider,
  Space,
  Table,
  Layout,
  InputNumber,
  Select,
  Spin
} from 'antd';

import swal from "sweetalert2";
import {getProduto, deleteProduto, createProduto, editProduto, getProdutoById} from "../../../router/CompraVenda/produto/produtos";
import {getTipoAll} from "../../../router/CompraVenda/tipo/tipos";
import {LoadingOutlined} from "@ant-design/icons";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Produto() {
  const [form] = Form.useForm();
  
  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])
  
  const [attorney, setAttorney] = useState([])
  const [attorneys, setAttorneys] = useState([])
  
  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  const [tiposAll, setTiposAll] = useState([])
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()
  
  const handleSubmit = async e => {
    if (edit[0]) {
      await editProduto(edit[1], e)
      
      setEdit([false, null])
      setStatus(true)
      setReload(new Date())
      
      swal.fire({
        title: "Produto editado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      await createProduto(e)
      
      setEdit([false, null])
      setStatus(true)
      setReload(new Date())
      
      swal.fire({
        title: "Produto criado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  
  useEffect(() => {
    (async () => {
      const attorneys = []
      
      const data =  await getProduto(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          window.location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      const tipos_all = await getTipoAll()
      
      const newTiposAll = tipos_all.data.map((item) => {
        return {
          value: item.id,
          label: item.nome,
        }
      })
      
      setTiposAll(newTiposAll)
      setPageAll(data.meta.total)
      
      for (const item of data.data) {
        const label = newTiposAll.find((element) => element.value === item.tipo_id)
        item.key = item.id
        item.tipo = label ? label.label : null;
        attorneys.push(item)
      }
      
      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [reload, page, search])
  
  const onRemove = ( id ) => {
    deleteProduto(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o produto!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o produto!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }
  
  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getProdutoById(id);
    setAttorney(attorney.data)
    
    setStatus(false)
    setEdit([true, id])
  }
  
  const onCreate = async ( ) => {
    form.setFieldsValue({
      nome: '',
      valor: '',
      quantidade: '',
      tipo_id: ''
    });
    setReload(new Date())
    setStatus(false)
    setEdit([false, null])
  }

  return (
    <Content>
      <Title level={3}>Produto</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {status && <>
        <Row>
          <Col span={8}>
            <Button type="primary" htmlType="reset" onClick={() => onCreate()} loading={loading}>Cadastrar</Button>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />
        
        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}>
            <Column title="Nome" dataIndex="nome" key="nome" />
            <Column title="Valor" dataIndex="valor" key="valor" render={(_, record) =>
              parseFloat(record.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            } />
            <Column title="Tipo" dataIndex="tipo" key="type" />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                  <Button type="primary" onClick={() => onRemove(record.id)} danger>Remover</Button>
                </Space>
              )}
            />
          </Table>
        </>}
      </>}
      
      {!status && <>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[
                  {
                    required: true,
                    message: 'Nome requerido',
                  },
                ]}
                initialValue={attorney.nome}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="valor"
                label="Valor"
                initialValue={attorney.valor ?? 0}
              >
                <InputNumber
                  style={{width: '100%'}}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={10}>
              <Form.Item
                name="tipo_id"
                label="Tipo"
                rules={[
                  {
                    required: true,
                    message: 'Tipo requerido',
                  },
                ]}
                initialValue={attorney.tipo_id}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={tiposAll}
                />
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                <Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default Produto