import React, { useEffect, useState } from 'react';
import {Layout, Menu} from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';

import HeaderT from './views/layout/Header';
import FooterT from './views/layout/Footer';
import Routes from './components/Routes';
import { AuthProvider } from './context/AuthContext';
import { getMe } from './router/Administrativo/usuarios/usuarios';
import jwt_decode from 'jwt-decode';
import swal from 'sweetalert2';

import {
  ApartmentOutlined, AuditOutlined, BankOutlined, BookOutlined,
  BuildOutlined,
  ExceptionOutlined, HddOutlined,
  ProductOutlined,
  ProfileOutlined,
  SolutionOutlined, UsergroupAddOutlined
} from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { innerWidth: width } = window;
  
  const token = localStorage.getItem('authTokens');
  useEffect(() => {
    (async () => {
      if (token) {
        await getMe().catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem('authTokens');
            window.location.replace('/login');
            swal.fire({
              title: 'Você foi desconectado...',
              icon: 'success',
              toast: true,
              timer: 3000,
              position: 'top-right',
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        });
      }
    })();
  }, [token]);
  
  const permission = token ? jwt_decode(token)?.permissions : null;

  const isAll = permission?.sigla;
  const isAdmin = permission?.sigla === 'ADM';
  const isAdminOrFinanceiro = permission?.sigla === 'ADM' || permission?.sigla === 'CV';
  const isAdminOrRecursosHumanos = permission?.sigla === 'ADM' || permission?.sigla === 'RH';
  
  const menu = [
    {
      label: 'Tipos de Produto',
      key: 'tipos',
      icon: <ProfileOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
    },
    {
      label: 'Produtos',
      key: 'produtos',
      icon: <ProductOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
    },
    {
      label: 'Produto Final',
      key: 'produto_final',
      icon: <BuildOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
    },
    {
      label: 'Orçamento',
      key: 'orcamento',
      icon: <SolutionOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
    },
    {
      label: 'Ordem de Serviço',
      key: 'os',
      icon: <ExceptionOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
    },
    {
      label: 'Estoque',
      key: 'estoque',
      icon: <ApartmentOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrFinanceiro ? 'block' : 'none' },
    },
    {
      label: 'Funcionários',
      key: 'funcionarios',
      icon: <UsergroupAddOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrRecursosHumanos ? 'block' : 'none' },
    },
    {
      label: 'Função',
      key: 'funcao',
      icon: <AuditOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrRecursosHumanos ? 'block' : 'none' },
    },
    {
      label: 'Departamento',
      key: 'departamento',
      icon: <HddOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrRecursosHumanos ? 'block' : 'none' },
    },
    {
      label: 'Escolaridade',
      key: 'escolaridade',
      icon: <BookOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrRecursosHumanos ? 'block' : 'none' },
    },
    {
      label: 'Banco',
      key: 'banco',
      icon: <BankOutlined style={{ fontSize: '170%'}} />,
      style: { display: isAdminOrRecursosHumanos ? 'block' : 'none' },
    }
  ];
  
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <Header style={{ padding: 0 }}>
            <HeaderT />
          </Header>
          <Layout>
            {width > 700 && token && (
              <Sider
                style={{
                  maxWidth: '150px !important',
                  minWidth: '150px !important',
                }}
                theme="light"
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
              >
                <Sider style={{
                  maxWidth: '150px !important',
                  minWidth: '150px !important',
                }} theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                  <Menu onClick={({ key }) => {window.location.replace(key)}} mode="inline" items={menu.filter(item => item.style.display !== 'none')} />
                </Sider>
              </Sider>
            )}
            
            {width < 700
              && token
              && <Menu mode="horizontal" onClick={({ key }) => {window.location.replace(key)}} items={menu.filter(item => item.style.display !== 'none')} />
            }
            
            <Layout style={{ minHeight: '125vh' }}>
              <Content style={{ margin: 0, minHeight: 280, padding: '24px' }}>
                <Routes
                  isAll={isAll}
                  isAdmin={isAdmin}
                  isAdminOrFinanceiro={isAdminOrFinanceiro}
                  isAdminOrRecursosHumanos={isAdminOrRecursosHumanos}
                />
              </Content>
              
              <Footer style={{ backgroundColor: '#ffffff' }}>
                <FooterT />
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AuthProvider>
    </Router>
  );
};

export default App;