import React, {useEffect, useState} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Space,
  Tabs, Select, InputNumber
} from 'antd';
import InputMask from 'react-input-mask';

import swal from "sweetalert2";
import {createFuncionarios, editFuncionarios} from "../../../router/RecursosHumanos/funcionario/funcionario";
import 'react-tabs/style/react-tabs.css';
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {getEscolaridadeAll} from "../../../router/RecursosHumanos/escolaridade/escolaridade";
import {getFuncao, getFuncaoAll} from "../../../router/RecursosHumanos/funcao/funcao";
import {getDepartamentoAll} from "../../../router/RecursosHumanos/departamento/departamento";
import {getTipoAll} from "../../../router/CompraVenda/tipo/tipos";
import {getBancoAll} from "../../../router/RecursosHumanos/banco/banco";

function FuncionariosForm({status, attorney, reload}) {
  const [form] = Form.useForm();

  // const [reload, setReload] = useState()
  const [edit, setEdit] = useState([false, null])
  
  const [funcaoAll, setFuncaoAll] = useState([])
  const [bancoAll, setBancoAll] = useState([])
  const [departamentoAll, setDepartamentoAll] = useState([])
  const [tipoAll, setTipoAll] = useState([])
  const [escolaridadeAll, setEscolaridadeAll] = useState([])
  
  const handleSubmit = async e => {
    if (edit[0]) {
      await editFuncionarios(edit[1], e)

      setEdit([false, null])
      status(true)
      reload(new Date())

      swal.fire({
        title: "Funcionários editado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      await createFuncionarios(e)

      setEdit([false, null])
      status(true)
      reload(new Date())

      swal.fire({
        title: "Funcionários criado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  
  useEffect(() => {
    (async () => {
      const escolaridade = await getEscolaridadeAll()
      const funcao = await getFuncaoAll()
      const banco = await getBancoAll()
      const departamento = await getDepartamentoAll()

      setEscolaridadeAll(escolaridade)
      setFuncaoAll(funcao)
      setBancoAll(banco)
      setDepartamentoAll(departamento)
    })()
  }, [])
  
  return (
    <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Dados Pessoais',
            key: '1',
            children: (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    name="nome"
                    label="Nome"
                    rules={[
                      {
                        required: true,
                        message: 'Nome requerido',
                      },
                    ]}
                    initialValue={attorney.nome}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    name="cpf"
                    label="CPF"
                    initialValue={attorney.cpf}
                  >
                    <InputMask class="mask" mask="999.999.999-99"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    name="rg"
                    label="RG"
                    initialValue={attorney.rg}
                  >
                    <InputMask class="mask" mask="999.999-99"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={2}>
                  <Form.Item
                    name="ssp"
                    label="ssp"
                    initialValue={attorney.ssp}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    name="expedicao"
                    label="Dt. expedição"
                    initialValue={attorney.expedicao}
                  >
                    <InputMask class="mask" mask="99/99/9999"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    name="pis"
                    label="Pis"
                    initialValue={attorney.pis}
                  >
                    <InputMask class="mask" mask="999.99999.99-99"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    name="data_de_nascimento"
                    label="Dt. de nasc."
                    initialValue={attorney.pis}
                  >
                    <InputMask class="mask" mask="99/99/9999"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    name="telefone"
                    label="Celular"
                    initialValue={attorney.telefone}
                  >
                    <InputMask class="mask" mask="99 9 9999 9999"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    name="funcao"
                    label="Função"
                    initialValue={attorney.funcao}
                    rules={[
                      {
                        required: true,
                        message: 'Função requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={funcaoAll}
                    />
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    name="departamento"
                    label="Departamento"
                    initialValue={attorney.departamento}
                    rules={[
                      {
                        required: true,
                        message: 'Departamento requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={departamentoAll}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ),
          },
          {
            label: 'Dados Adcionais',
            key: '2',
            children: (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="escolaridade"
                    label="Escolaridade"
                    initialValue={attorney.escolaridade}
                    rules={[
                      {
                        required: true,
                        message: 'Escolaridade requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={escolaridadeAll}
                    />
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="data_admissao"
                    label="Dt. admissão"
                    initialValue={attorney.data_admissao}
                    rules={[
                      {
                        required: true,
                        message: 'Dt. admissão requerido',
                      },
                    ]}
                  >
                    <InputMask class="mask" mask="99/99/9999"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="data_demissao"
                    label="Dt. demissão"
                    initialValue={attorney.data_demissao}
                  >
                    <InputMask class="mask" mask="99/99/9999"/>
                  </Form.Item>
                </Col>
              </Row>
            )
          },
          {
            label: 'Dados Bancarios',
            key: '3',
            children: (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    name="banco"
                    label="Banco"
                    initialValue={attorney.banco}
                    rules={[
                      {
                        required: true,
                        message: 'Banco requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={bancoAll}
                    />
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={7}>
                  <Form.Item
                    name="agencia"
                    label="Agência"
                    initialValue={attorney.data_admissao}
                  >
                    <InputMask class="mask" mask="9999-9"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={7}>
                  <Form.Item
                    name="conta"
                    label="Conta"
                    initialValue={attorney.conta}
                  >
                    <InputMask class="mask" mask="99999999999-9"/>
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="pix"
                    label="Chave Pix"
                    initialValue={attorney.pix}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            ),
          },
        ]}
      />

      <Col span={24}>
        <Space size="middle">
          <Button type="primary" htmlType="submit">Salvar</Button>
          <Button type="primary" danger onClick={() => status(true)}>Cancelar</Button>
        </Space>
      </Col>
    </Form>
  )
}

export default FuncionariosForm