import React, {useContext} from 'react'
import { Menu } from 'antd';
import AuthContext from '../../context/AuthContext'

export default function Header() {
  const { user, logoutUser } = useContext(AuthContext)
  const token = localStorage.getItem("authTokens")

  return (
    <>
      <Menu mode="horizontal" style={{fontSize: '20px', justifyContent: 'end'}}>
        <Menu.Item key="home"><a style={{textDecoration: "none"}} href="/">Inicio</a></Menu.Item>
        <Menu.Item key="produtos"><a style={{textDecoration: "none"}} href="/produto">Produtos</a></Menu.Item>
        <Menu.Item key="contato"><a style={{textDecoration: "none"}} href="/contato">Contato</a></Menu.Item>
        
        {token === null &&
          <Menu.Item key="login"><a style={{textDecoration: "none"}} href="/login">Área Restrita</a></Menu.Item>
        }
        
        {token !== null &&
          <Menu.SubMenu title={'Configurações'} key="config">
            {user?.permissions?.id === 1 &&
              <Menu.Item key="usuarios"><a style={{textDecoration: "none"}} href="/usuarios">Usuarios</a></Menu.Item>
            }
            
            <Menu.Item key="perfil"><a style={{textDecoration: "none"}} href="/perfil">Perfil</a></Menu.Item>
            
            <Menu.Item key="sair"><a style={{textDecoration: "none"}} href="/" onClick={logoutUser}>Sair</a></Menu.Item>
          </Menu.SubMenu>
        }
      </Menu>
    </>
  )
}