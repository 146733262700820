import React, {useState, useEffect} from 'react'
import {
  Button,
  Typography,
  Divider,
  Space,
  Table,
  Layout,
  Spin, Col, Input, Row,
} from 'antd';

import swal from "sweetalert2";
import {LoadingOutlined} from "@ant-design/icons";
import {getOrdemServico, getOrdemServicoByIdPdf} from "../../../router/CompraVenda/ordem_servico/ordem_servico";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Tipo() {
  const [attorneys, setAttorneys] = useState([])

  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()
  
  useEffect(() => {
    (async () => {
      const attorneys = []
      const data =  await getOrdemServico(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          window.location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      setPageAll(data.meta.total)

      for (const item of data.data) {
        item.key = item.id
        attorneys.push(item)
      }

      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [page, search])

  const gerarPdf = async (id) => {
    await getOrdemServicoByIdPdf(id);
  }

  return (
    <Content>
      <Title level={3}>Ordem de Serviço</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {!loading && <>
        <Row>
          <Col span={8}>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />

        <Table dataSource={attorneys} pagination={{
          onChange: setPage,
          pageSize: 15,
          total: pageAll,
        }}
         scroll={{
           x: true,
         }}>
          <Column title="Descrição" dataIndex="descricao" key="descricao" />
          <Column
            title="Ações"
            key="action"
            render={(_, record) => (
                <Space size="middle">
                  <Button style={{backgroundColor: 'rgb(189, 186, 15)'}} type="primary" onClick={() => gerarPdf(record.id)}>Imprimir</Button>
                </Space>
            )}
          />
        </Table>
      </>}
    </Content>
  )
}

export default Tipo