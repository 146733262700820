import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getTipo = async (page, search = '') => {
    const { data } = await api.get(`/tipos?page=${page}&search=${search}`);
    return data;
};

export const getTipoAll = async () => {
    const { data } = await api.get(`/tipos_all`);
    return data;
};

export const getTipoById = async (id) => {
    const { data } = await api.get(`/tipos/${id}`);
    return data;
};

export const createTipo = async (data) =>
    await api.post('/tipos', data);

export const editTipo = async (id, data) =>
    await api.put(`/tipos/${id}`, data);

export const deleteTipo = async (id) =>
    await api.delete(`/tipos/${id}`);