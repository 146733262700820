import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const useAxios = () => {
    try {
        const token = localStorage.getItem("authTokens")

        const axiosInstance = axios.create({
            baseURL,
        });

        axiosInstance.interceptors.request.use(
            config => {
                config.headers.Authorization = `Bearer ${token}`
                return Promise.resolve(config)
            },
            error => {
                return error
            },
        )

        return axiosInstance;
    } catch (e) {

    }
};

export default useAxios;