import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getOrcamento = async (page, search = '') => {
    const { data } = await api.get(`/orcamento?page=${page}&search=${search}`);
    return data;
};

export const getOrcamentoById = async (id) => {
    const { data } = await api.get(`/orcamento/${id}`);
    return data;
};

export const getOrcamentoByIdPdf = async (id) => {
    const response = await api.get(`/orcamento_pdf/${id}?origem=orcamento`, {
        responseType: 'blob'  // Ensure the response is handled as binary data
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
};

export const getOrcamentoByIdAprove = async (id) => {
    const { data } = await api.get(`/orcamento_aprove/${id}`);
    return data;
};

export const createOrcamento = async (data) =>
    await api.post('/orcamento', data);

export const editOrcamento = async (id, data) =>
    await api.put(`/orcamento/${id}`, data);

export const deleteOrcamento = async (id) =>
    await api.delete(`/orcamento/${id}`);