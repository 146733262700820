import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getProduto = async (page, search = '') => {
    const { data } = await api.get(`/produtos?page=${page}&search=${search}`);
    return data;
};

export const getProdutoAll = async () => {
    const { data } = await api.get(`/produtos_all`);
    return data;
};

export const getProdutoById = async (id) => {
    const { data } = await api.get(`/produtos/${id}`);
    return data;
};

export const createProduto = async (data) =>
    await api.post('/produtos', data);

export const editProduto = async (id, data) =>
    await api.put(`/produtos/${id}`, data);

export const deleteProduto = async (id) =>
    await api.delete(`/produtos/${id}`);