import React from 'react'
import {Col, Row, Typography, Button, Form, Input, Space} from 'antd';

const { TextArea } = Input;

const { Paragraph} = Typography;

function Homepage() {
  const [form] = Form.useForm();
  
  const handleSubmit = (values) => {
    // const user = values.username
    // const password = values.password
    
    // loginUser(user, password)
  }
  
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{margin: '0', padding: '10px'}} justify="center">
        <Col>
          <Paragraph>
            Por favor, utilize o formulário abaixo para compartilhar suas dúvidas, sugestões ou
          </Paragraph>
          <Paragraph>
            solicitações de suporte. Nossa equipe responderá o mais rápido possível.
          </Paragraph>
        </Col>
      </Row>
      
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{margin: '0', padding: '10px'}} justify="center">
        <Col>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item
                  name="assunto"
                  label="Assunto"
                  rules={[
                    {
                      required: true,
                      message: 'Assunto requerido',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  name="nome"
                  label="Nome Completo"
                  rules={[
                    {
                      required: true,
                      message: 'Nome requerido',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Email requerido',
                    },
                    {
                      type: 'email',
                      message: 'Email não valido',
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Email requerido',
                    }
                  ]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
              
              <Col span={24}>
                <Space size="middle">
                  <Button type="primary" htmlType="submit">Salvar</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Homepage