import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getBanco = async (page, search = '') => {
    const { data } = await api.get(`/banco?page=${page}&search=${search}`);
    return data;
};

export const getBancoAll = async () => {
    const banco = []
    const { data } = await api.get(`/banco_all`);
    
    for (const item of data.data) {
        console.log(item)
        item.value = item.id
        item.label = item.digito + ' - ' + item.nome
        banco.push(item)
    }
    
    return banco;
};

export const getBancoById = async (id) => {
    const { data } = await api.get(`/banco/${id}`);
    return data;
};

export const createBanco = async (data) =>
    await api.post('/banco', data);

export const editBanco = async (id, data) =>
    await api.put(`/banco/${id}`, data);

export const deleteBanco = async (id) =>
    await api.delete(`/banco/${id}`);