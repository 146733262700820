import React from 'react';
import { Col, Row, Typography, Divider, Carousel, Card } from 'antd';

import backgroundOne from '../../assets/images/1.jpg';
import backgroundTwo from '../../assets/images/2.jpg';
import backgroundThree from '../../assets/images/3.jpg';
import backgroundFour from '../../assets/images/4.jpg';
import backgroundFive from '../../assets/images/5.jpg';
import backgroundSix from '../../assets/images/6.jpg';

const { Meta } = Card;
const { Paragraph, Title } = Typography;

function Homepage() {
  const slides = [
    { id: 1, image: backgroundOne },
    { id: 2, image: backgroundTwo },
    { id: 3, image: backgroundThree },
    { id: 4, image: backgroundFour },
    { id: 5, image: backgroundFive },
    { id: 6, image: backgroundSix },
  ];

  return (
    <>
      <Carousel autoplay className="w-full overflow-hidden">
        {slides.map((slide) => (
          <div className="relative" key={slide.id}>
            <img
              className="w-full object-cover"
              style={{ width: '100%', height: '60vh', objectFit: 'cover', objectPosition: 'center' }}
              src={slide.image}
              alt={`Slide ${slide.id}`}
            />
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>
        ))}
      </Carousel>

      <Divider />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: '0', padding: '10px' }} justify="center">
        <Col>
          <Title>Criamos soluções que agregam resultados!</Title>
        </Col>
        <Col>
          <Paragraph>
            Atuamos no ramo de fabricação de implementos de mineração, desde 2010 no mercado.
          </Paragraph>
          <Paragraph>
            Matriz em Douradina - MS, com filial em Primavera do Leste - MT.
          </Paragraph>
          <Paragraph>
            Empresa consolidada e atuante em todo o Brasil.
          </Paragraph>
        </Col>
      </Row>

      <Divider />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: '0', padding: '0' }} justify="space-evenly">
        {slides.slice(0, 3).map((slide) => (
          <Col key={slide.id}>
            <Card
              style={{ width: 350 }}
              cover={
                <img
                  style={{ height: 400, objectFit: 'cover', objectPosition: 'center' }}
                  alt={`Card ${slide.id}`}
                  src={slide.image}
                />
              }
            >
              <Meta title={`Card title ${slide.id}`} description="This is the description" />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Homepage;
