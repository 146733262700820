import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getFuncao = async (page, search = '') => {
    const { data } = await api.get(`/funcao?page=${page}&search=${search}`);
    return data;
};

export const getFuncaoAll = async () => {
    const funcao = []
    const { data } = await api.get(`/funcao_all`);

    for (const item of data.data) {
        item.value = item.id
        item.label = item.nome
        funcao.push(item)
    }
    
    return funcao;
};

export const getFuncaoById = async (id) => {
    const { data } = await api.get(`/funcao/${id}`);
    return data;
};

export const createFuncao = async (data) =>
    await api.post('/funcao', data);

export const editFuncao = async (id, data) =>
    await api.put(`/funcao/${id}`, data);

export const deleteFuncao = async (id) =>
    await api.delete(`/funcao/${id}`);