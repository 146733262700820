import React, {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Typography,
  Row,
  Divider,
  Space,
  Table,
  Layout,
  InputNumber,
  Spin,
  Select,
  Popconfirm
} from 'antd';

import swal from "sweetalert2";
import {CloseOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {getProdutoAll} from "../../../router/CompraVenda/produto/produtos";
import {
  createOrcamento,
  deleteOrcamento,
  editOrcamento,
  getOrcamento,
  getOrcamentoById,
  getOrcamentoByIdAprove,
  getOrcamentoByIdPdf
} from "../../../router/CompraVenda/orcamento/orcamento";
import {getProdutoFinalAll} from "../../../router/CompraVenda/produto/produto_final";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Tipo() {
  const [form] = Form.useForm();

  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])

  const [attorney, setAttorney] = useState([])
  const [attorneys, setAttorneys] = useState([])

  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  
  const [loading, setLoading] = useState(true)
  
  const [attorneyOrcamentoProduto, setAttorneyOrcamentoProduto] = useState([])
  const [attorneyOrcamentoProdutoFinal, setAttorneyOrcamentoProdutoFinal] = useState([])
  
  const [produtosAll, setProdutosAll] = useState([])
  const [produtosFinalAll, setProdutosFinalAll] = useState([])
  
  const [search, setSearch] = useState()
  
  const handleSubmit = async e => {
    if (edit[0]) {
      await editOrcamento(edit[1], e)
      
      setEdit([false, null])
      setStatus(true)
      
      swal.fire({
        title: "Orçamento editado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      await createOrcamento(e)
      
      setEdit([false, null])
      setStatus(true)
      
      swal.fire({
        title: "Orçamento criado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
    
    setReload(new Date())
  }

  useEffect(() => {
    (async () => {
      const attorneys = []
      const data =  await getOrcamento(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")

          window.location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      const produtos_all =  await getProdutoAll()
      const produtos_final_all =  await getProdutoFinalAll()
      
      const newProdutosAll = produtos_all.data.map((item) => {
        return {
          value: item.id,
          label: item.nome + ' - ' + item.tipos.nome,
        }
      })
      
      const newProdutosFinalAll = produtos_final_all.data.map((item) => {
        return {
          value: item.id,
          label: item.nome,
        }
      })
      
      setProdutosAll(newProdutosAll)
      setProdutosFinalAll(newProdutosFinalAll)
      
      setPageAll(data.meta.total)

      for (const item of data.data) {
        item.key = item.id
        attorneys.push(item)
      }

      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [reload, page, search])

  const onRemove = ( id ) => {
    deleteOrcamento(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o orçamento!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o orçamento!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }

  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getOrcamentoById(id);
    
    setAttorney(attorney.data)
    setAttorneyOrcamentoProduto(attorney.data.orcamento_produtos)
    setAttorneyOrcamentoProdutoFinal(attorney.data.orcamento_produtos_final)

    setStatus(false)
    setEdit([true, id])
  }

  const onCreate = async ( ) => {
    form.setFieldsValue({
      descricao: '',
      produtos: [],
      produtos_final: []
    });
    setStatus(false)
    setEdit([false, null])
  }
  
  const gerarPdf = async (id) => {
    await getOrcamentoByIdPdf(id);
  }
  
  const confirm = async (id) => {
    await getOrcamentoByIdAprove(id);
    
    setReload(new Date())
  }

  return (
    <Content>
      <Title level={3}>Orçamento</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {status && <>
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={() => onCreate()} loading={loading}>Cadastrar</Button>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />
        
        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}>
            <Column title="Descrição" dataIndex="descricao" key="descricao" />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                  <Space size="middle">
                    <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                    <Button type="primary" onClick={() => onRemove(record.id)} danger>Remover</Button>
                    <Button style={{backgroundColor: 'rgb(189, 186, 15)'}} type="primary" onClick={() => gerarPdf(record.id)}>Imprimir</Button>
                    <Popconfirm
                      title="Aprovar Orçamento"
                      description='Dejesa aprovar o orçamento e transformalo em uma Ordem de Serviço?'
                      onConfirm={() => confirm(record.id)}
                      okText="Confirmar"
                      cancelText="Cancelar"
                    >
                      <Button style={{backgroundColor: '#52c41a'}} type="primary">Aprovar</Button>
                    </Popconfirm>
                  </Space>
              )}
            />
          </Table>
        </>}
      </>}

      {!status && <>
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                  name="descricao"
                  label="Descrição"
                  rules={[
                    {
                      required: true,
                      message: 'Descrição requerido',
                    },
                  ]}
                  initialValue={attorney.descricao}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} xl={12}>
              <Title level={5}>Produto Estoque</Title>
              
              <Divider/>
              
              <Form.List name="produtos" initialValue={attorneyOrcamentoProduto}>
                {(fields, {add, remove}) => (
                  <>
                    {fields.map(({key, name}) => (
                      <div style={{padding: '0 3rem'}} key={key}>
                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                          <Col xs={5} sm={5} md={5}>
                            <Form.Item
                              name={[name, 'quantidade']}
                              label="Quant."
                              rules={[
                                {
                                  required: true,
                                  message: 'Quantidade requerida',
                                },
                              ]}
                              initialValue={attorney.quantidade}
                            >
                              <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                          </Col>
                          
                          <Col xs={18} sm={18} md={18}>
                            <Form.Item
                              name={[name, 'produtos_id']}
                              label="Item"
                              rules={[
                                {
                                  required: true,
                                  message: 'Item requerido',
                                },
                              ]}
                            >
                              <Select
                                style={{width: '100%'}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={produtosAll}
                              />
                            </Form.Item>
                          </Col>
                          
                          <CloseOutlined onClick={() => remove(name)}/>
                        </Row>
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                        Adcionar Produto Estoque
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            
            <Col xs={24} sm={24} xl={12}>
              <Title level={5}>Produto Final</Title>
              
              <Divider/>
              
              <Form.List name="produtos_final" initialValue={attorneyOrcamentoProdutoFinal}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name}) => (
                      <div style={{padding: '0 3rem'}} key={key}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={5} sm={5} md={5}>
                            <Form.Item
                              name={[name, 'quantidade']}
                              label="Quant."
                              rules={[
                                {
                                  required: true,
                                  message: 'Quantidade requerida',
                                },
                              ]}
                              initialValue={attorney.quantidade}
                            >
                              <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                          </Col>
                          
                          <Col xs={18} sm={18} md={18}>
                            <Form.Item
                              name={[name, 'produtos_final_id']}
                              label="Item Final"
                              rules={[
                                {
                                  required: true,
                                  message: 'Item requerido',
                                },
                              ]}
                            >
                              <Select
                                style={{width: '100%'}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={produtosFinalAll}
                              />
                            </Form.Item>
                          </Col>
                          
                          <CloseOutlined onClick={() => remove(name)} />
                        </Row>
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Adcionar Produto Final
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                <Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default Tipo