import React, {useState, useEffect} from 'react'
import {Col, Button, Form, Input, Typography, Row, Divider, Space, Table, Layout, InputNumber, Popconfirm, Select, Spin} from 'antd';

import swal from "sweetalert2";
import {getProdutoFinal, deleteProdutoFinal, createProdutoFinal, editProdutoFinal, getProdutoFinalById} from "../../../router/CompraVenda/produto/produto_final";
import {getProdutoFinalProdutosById} from "../../../router/CompraVenda/produto/produto_final_produtos";
import {getTipoAll} from "../../../router/CompraVenda/tipo/tipos";
import {CloseOutlined, PlusOutlined, LoadingOutlined} from "@ant-design/icons";
import {getProdutoAll} from "../../../router/CompraVenda/produto/produtos";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function ProdutoFinal() {
  const [form] = Form.useForm();
  
  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])
  
  const [attorney, setAttorney] = useState([])
  const [attorneyProdutos, setAttorneyProdutos] = useState([])
  const [attorneys, setAttorneys] = useState([])
  
  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  const [tiposAll, setTiposAll] = useState([])
  const [produtosAll, setProdutosAll] = useState([])
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()
  
  const handleSubmit = async e => {
    if (edit[0]) {
      await editProdutoFinal(edit[1], e)

      setEdit([false, null])
      setStatus(true)
      setReload(new Date())
      
      swal.fire({
        title: "Produto Final editado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      await createProdutoFinal(e)
      
      setEdit([false, null])
      setStatus(true)
      setReload(new Date())
      
      swal.fire({
        title: "Produto Final criado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  
  useEffect(() => {
    (async () => {
      try{
        const attorneys = []
        
        const data =  await getProdutoFinal(page, search)
        const tipos_all =  await getTipoAll().catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("authTokens")
            
            window.location.replace('/')
            
            swal.fire({
              title: "Você foi desconectado...",
              icon: "success",
              toast: true,
              timer: 3000,
              position: 'top-right',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })

        const produtos_all =  await getProdutoAll()
        
        const newTiposAll = tipos_all.data.map((item) => {
          return {
            value: item.id,
            label: item.nome,
          }
        })
        
        const newProdutosAll = produtos_all.data.map((item) => {
          return {
            value: item.id,
            label: item.nome + ' - ' + item.tipos.nome,
          }
        })
        
        setTiposAll(newTiposAll)
        setProdutosAll(newProdutosAll)
        setPageAll(data.meta.total)
        
        for (const item of data.data) {
          const label = newTiposAll.find((element) => element.value === item.tipo_id)
          item.key = item.id
          item.tipo = label ? label.label : null;
          attorneys.push(item)
        }
        
        setAttorneys(attorneys)
        setLoading(false)
      } catch (error) {
        swal.fire({
          title: "Errado ao buscar produto final!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      }
    })()
  }, [reload, page, search])
  
  const onRemove = ( id ) => {
    deleteProdutoFinal(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o produto!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o produto!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }
  
  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getProdutoFinalById(id);
    const attorneyProdutos = await getProdutoFinalProdutosById(id);
    
    setAttorney(attorney.data)
    setAttorneyProdutos(attorneyProdutos.data)
    
    setStatus(false)
    setEdit([true, id])
  }
  
  const onCreate = async ( ) => {
    form.setFieldsValue({
      nome: '',
      valor: '',
      tipo_id: '',
      produtos: []
    });
    
    setReload(new Date())
    setStatus(false)
    setEdit([false, null])
  }
  
  return (
    <Content>
      <Title level={3}>Produto Final</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}
      
      {status && <>
        <Row>
          <Col span={8}>
            <Button type="primary" htmlType="reset" onClick={() => onCreate()} loading={loading}>Cadastrar</Button>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>

        <Divider />

        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}
          >
            <Column title="Nome" dataIndex="nome" key="nome" />
            <Column title="Valor" dataIndex="valor" key="valor" render={(_, record) =>
              parseFloat(record.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            }/>
            <Column title="Tipo" dataIndex="tipo" key="type" />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                  <Popconfirm title="Deseja remover?" onConfirm={() => onRemove(record.id)}>
                    <Button type="primary" danger>Remover</Button>
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        </>}
      </>}
      
      {!status && <>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={14}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[
                  {
                    required: true,
                    message: 'Nome requerido',
                  },
                ]}
                initialValue={attorney.nome}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={12} md={4}>
              <Form.Item
                name="valor"
                label="Valor"
                initialValue={attorney.valor ?? 0}
              >
                <InputNumber
                  style={{width: '100%'}}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="tipo_id"
                label="Tipo"
                initialValue={attorney.tipo_id}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={tiposAll}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={24}>
              <Form.List name="produtos" initialValue={attorneyProdutos}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name}) => (
                      <div style={{padding: '0 3rem'}} key={key}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={18} sm={18} md={18}>
                            <Form.Item
                              name={[name, 'produtos_id']}
                              label="Produto"
                              rules={[
                                {
                                  required: true,
                                  message: 'Produto requerido',
                                },
                              ]}
                            >
                              <Select
                                style={{width: '100%'}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={produtosAll}
                              />
                            </Form.Item>
                          </Col>
                        
                          <Col xs={5} sm={5} md={5}>
                            <Form.Item
                              name={[name, 'quantidade']}
                              label="Quantidade"
                              rules={[
                                {
                                  required: true,
                                  message: 'Quantidade requerida',
                                },
                              ]}
                              initialValue={attorney.quantidade}
                            >
                              <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                          </Col>
                          <CloseOutlined onClick={() => remove(name)} />
                        </Row>
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Adcionar Produto
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                <Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default ProdutoFinal