import React, {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Typography,
  Row,
  Divider,
  Space,
  Table,
  Layout,
  Spin
} from 'antd';

import swal from "sweetalert2";
import {getBanco, deleteBanco, createBanco, editBanco, getBancoById} from "../../../router/RecursosHumanos/banco/banco";
import {LoadingOutlined} from "@ant-design/icons";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Banco() {
  const [form] = Form.useForm();

  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])

  const [attorney, setAttorney] = useState([])
  const [attorneys, setAttorneys] = useState([])

  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()
  
  const handleSubmit = async e => {
    if (edit[0]) {
      await editBanco(edit[1], e)

      setEdit([false, null])
      setStatus(true)
      setReload(new Date())

      swal.fire({
        title: "Banco editado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      await createBanco(e)

      setEdit([false, null])
      setStatus(true)
      setReload(new Date())

      swal.fire({
        title: "Banco criado",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }

  useEffect(() => {
    (async () => {
      const attorneys = []
      const data =  await getBanco(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          window.location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      setPageAll(data.meta.total)

      for (const item of data.data) {
        item.key = item.id
        attorneys.push(item)
      }
      
      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [reload, page, search])

  const onRemove = ( id ) => {
    deleteBanco(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o Banco!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o Banco!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }

  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getBancoById(id);
    setAttorney(attorney.data)
    
    setStatus(false)
    setEdit([true, id])
  }

  const onCreate = async ( ) => {
    form.setFieldsValue({
      nome: '',
      digito: '',
    });
    setStatus(false)
    setEdit([false, null])
  }
  
  return (
    <Content>
      <Title level={3}>Banco</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}

      {status && <>
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={() => onCreate()} loading={loading}>Cadastrar</Button>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />
        
        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}>
            <Column title="Nome" dataIndex="nome" key="nome" />
            <Column title="Digito" dataIndex="digito" key="digito" />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                  <Space size="middle">
                    <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                    <Button type="primary" onClick={() => onRemove(record.id)} danger>Remover</Button>
                  </Space>
              )}
            />
          </Table>
        </>}
      </>}

      {!status && <>
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={10}>
              <Form.Item
                  name="nome"
                  label="Nome"
                  rules={[
                    {
                      required: true,
                      message: 'Nome requerido',
                    },
                  ]}
                  initialValue={attorney.nome}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={2}>
              <Form.Item
                  name="digito"
                  label="Digito"
                  rules={[
                    {
                      required: true,
                      message: 'Digito requerido',
                    },
                  ]}
                  initialValue={attorney.digito}
              >
                <Input />
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">Salvar</Button>
                <Button type="primary" danger onClick={() => setStatus(true)}>Cancelar</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </>}
    </Content>
  )
}

export default Banco