import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getMe = async () => {
    const { data } = await api.post(`/me`);
    return data;
};

export const getUsuarios = async (page, search = '') => {
    const { data } = await api.get(`/usuarios?page=${page}&search=${search}`);
    return data;
};

export const getUsuariosById = async (id) => {
    const { data } = await api.get(`/usuarios/${id}`);
    return data;
};

export const createUsuarios = async (data) =>
    await api.post('/usuarios', data);

export const editUsuarios = async (id, data) =>
    await api.put(`/usuarios/${id}`, data);

export const deleteUsuarios = async (id) =>
    await api.delete(`/usuarios/${id}`);