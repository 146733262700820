import React from 'react'
import { Col, Row, Typography, Divider, Card } from 'antd';

import backgroundOne from '../../assets/images/1.jpg';
import backgroundTwo from '../../assets/images/2.jpg';
import backgroundThree from '../../assets/images/3.jpg';
import backgroundFour from '../../assets/images/4.jpg';
import backgroundFive from '../../assets/images/5.jpg';
import backgroundSix from '../../assets/images/6.jpg';

const { Meta } = Card;

const { Title } = Typography;

function Homepage() {
  
  const slides = [
    {
      id: 1,
      image: backgroundOne,
    },
    {
      id: 2,
      image: backgroundTwo,
    },
    {
      id: 3,
      image: backgroundThree,
    },
    {
      id: 4,
      image: backgroundFour,
    },
    {
      id: 5,
      image: backgroundFive,
    },
    {
      id: 6,
      image: backgroundSix,
    },
  ];
  
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{margin: '0', padding: '10px'}} justify="center">
        <Col>
          <Title>Trabalhamos com diversos produtos</Title>
        </Col>
      </Row>
      
      <Divider />
      
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{margin: '0', padding: '0'}} justify="space-evenly">
        <Col>
          <Card
            style={{
              width: 350,
            }}
            cover={
              <img
                style={{
                  height: 300,
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
                alt="example"
                src={slides[0]['image']}
              />
            }
          >
            <Meta
              title="Card title"
              description="This is the description"
            />
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              width: 350,
            }}
            cover={
              <img
                style={{
                  height: 300,
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
                alt="example"
                src={slides[1]['image']}
              />
            }
          >
            <Meta
              title="Card title"
              description="This is the description"
            />
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              width: 350,
            }}
            cover={
              <img
                style={{
                  height: 300,
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
                alt="example"
                src={slides[2]['image']}
              />
            }
          >
            <Meta
              title="Card title"
              description="This is the description"
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Homepage