import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../views/layout/Home';
import Product from '../views/layout/Produtos';
import Contact from '../views/layout/Contato';
import Loginpage from '../views/Login/Login';

import Perfil from '../views/Administrativo/Usuarios/Perfil';

import Usuarios from '../views/Administrativo/Usuarios/Usuarios';

import Funcionarios from '../views/RecursosHumanos/Funcionarios/Funcionarios';
import Funcao from '../views/RecursosHumanos/Funcao/Funcao';

import Tipo from '../views/CompraVenda/Tipos/Tipos';
import Orcamento from '../views/CompraVenda/Orcamento/Orcamento';
import Produto from '../views/CompraVenda/Produto/Produto';
import ProdutoFinal from '../views/CompraVenda/Produto/ProdutoFinal';
import Estoque from '../views/CompraVenda/Estoque/Estoque';
import OrdemServico from '../views/CompraVenda/OrdemServico/OrdemServico';
import Escolaridade from "../views/RecursosHumanos/Escolaridade/Escolaridade";
import Departamento from "../views/RecursosHumanos/Departamento/Departamento";
import Banco from "../views/RecursosHumanos/Banco/Banco";

const Routes = ({ isAll, isAdmin, isAdminOrFinanceiro, isAdminOrRecursosHumanos }) => (
  <Switch>
    <>
      <Route component={Home} path="/" exact />
      <Route component={Product} path="/produto" exact />
      <Route component={Contact} path="/contato" exact />
      <Route component={Loginpage} path="/login" />

      {isAll ? (
        <Route component={Perfil} path="/perfil" exact />
      ) : null}
      
      {isAdmin ? (
        <Route component={Usuarios} path="/usuarios" exact />
      ) : null}
      
      {isAdminOrRecursosHumanos ? (
        <>
          <Route component={Banco} path="/banco" />
          <Route component={Departamento} path="/departamento" />
          <Route component={Escolaridade} path="/escolaridade" />
          <Route component={Funcao} path="/funcao" />
          <Route component={Funcionarios} path="/funcionarios" />
        </>
      ) : null}
      
      {isAdminOrFinanceiro ? (
        <>
          <Route component={Tipo} path="/tipos" exact />
          <Route component={Orcamento} path="/orcamento" exact />
          <Route component={OrdemServico} path="/os" exact />
          <Route component={Estoque} path="/estoque" exact />
          <Route component={Produto} path="/produtos" exact />
          <Route component={ProdutoFinal} path="/produto_final" exact />
        </>
      ) : null}
    </>
  </Switch>
);

export default Routes;