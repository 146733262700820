import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getFuncionarios = async (page, search = '') => {
    const { data } = await api.get(`/funcionarios?page=${page}&search=${search}`);
    return data;
};

export const getFuncionariosById = async (id) => {
    const { data } = await api.get(`/funcionarios/${id}`);
    return data;
};

export const createFuncionarios = async (data) =>
    await api.post('/funcionarios', data);

export const editFuncionarios = async (id, data) =>
    await api.put(`/funcionarios/${id}`, data);

export const deleteFuncionarios = async (id) =>
    await api.delete(`/funcionarios/${id}`);