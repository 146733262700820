import React, {useState, useEffect} from 'react'
import {
  Col,
  Button,
  Form,
  Input,
  Typography,
  Row,
  Divider,
  Space,
  Table,
  Layout,
  Spin,
  Tabs, Select
} from 'antd';
import InputMask from 'react-input-mask';

import swal from "sweetalert2";
import {getFuncionarios, deleteFuncionarios, createFuncionarios, editFuncionarios, getFuncionariosById} from "../../../router/RecursosHumanos/funcionario/funcionario";
import {LoadingOutlined} from "@ant-design/icons";
import 'react-tabs/style/react-tabs.css';
import FuncionariosForm from "./Form";

const { Title } = Typography;
const { Content } = Layout;
const { Column } = Table;

function Funcionarios() {
  const [form] = Form.useForm();

  const [reload, setReload] = useState()
  const [status, setStatus] = useState(true)
  const [edit, setEdit] = useState([false, null])

  const [attorney, setAttorney] = useState([])
  const [attorneys, setAttorneys] = useState([])

  const [page, setPage] = useState(1)
  const [pageAll, setPageAll] = useState(0)
  
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState()

  useEffect(() => {
    (async () => {
      const attorneys = []
      const data =  await getFuncionarios(page, search).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("authTokens")
          
          window.location.replace('/')
          
          swal.fire({
            title: "Você foi desconectado...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
      
      setPageAll(data.meta.total)
      
      for (const item of data.data) {
        item.key = item.id
        attorneys.push(item)
      }
      
      setAttorneys(attorneys)
      setLoading(false)
    })()
  }, [reload, page, search])

  const onRemove = ( id ) => {
    deleteFuncionarios(id)
      .then(async () => {
        setReload(new Date())
        swal.fire({
          title: "Você removeu o Funcionário!",
          icon: "success",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
      .catch(() => {
        swal.fire({
          title: "Algo deu errado ao remover o Funcionário!",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        })
      })
  }

  const onEdit = async ( id ) => {
    form.resetFields();
    const attorney = await getFuncionariosById(id);
    setAttorney(attorney.data)
    
    setStatus(false)
    setEdit([true, id])
  }

  const onCreate = async ( ) => {
    setAttorney([])
    setStatus(false)
    setEdit([false, null])
  }
  
  return (
    <Content>
      <Title level={3}>Funcionários</Title>
      
      <Divider />
      
      {loading && <>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
              spin
            />
          }
        />
      </>}

      {status && <>
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={() => onCreate()} loading={loading}>Cadastrar</Button>
          </Col>
          <Col span={8} offset={8}>
            <Input placeholder="Pesquisa" onChange={(e) => e.target.value.length >= 3 ? setSearch(e.target.value) : setSearch('')} />
          </Col>
        </Row>
        
        <Divider />
        
        {!loading && <>
          <Table dataSource={attorneys} pagination={{
            onChange: setPage,
            pageSize: 15,
            total: pageAll,
          }}
           scroll={{
             x: true,
           }}>
            <Column title="Nome" dataIndex="nome" key="nome" />
            <Column title="Cpf" dataIndex="cpf" key="cpf" />
            <Column
              title="Ações"
              key="action"
              render={(_, record) => (
                  <Space size="middle">
                    <Button type="primary" onClick={() => onEdit(record.id)}>Editar</Button>
                    <Button type="primary" onClick={() => onRemove(record.id)} danger>Remover</Button>
                  </Space>
              )}
            />
          </Table>
        </>}
      </>}

      {!status && <>
        <FuncionariosForm status={setStatus} attorney={attorney} reload={setReload}/>
      </>}
    </Content>
  )
}

export default Funcionarios