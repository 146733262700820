import React from 'react';
import {Col, Row, Typography, List, Divider} from 'antd';
import {FacebookOutlined, InstagramOutlined, WhatsAppOutlined} from "@ant-design/icons";

const { Paragraph, Title } = Typography;

function NavFooter() {
  const data = [
    {
      title: 'Instagran',
      link: 'https://www.instagram.com/tavares_metalurgica/',
      icon: <InstagramOutlined />
    },
    {
      title: 'WhatsApp',
      icon: <WhatsAppOutlined />
    },
    {
      title: 'Facebook',
      icon: <FacebookOutlined />
    },
  ];
  
  return (
    <>
      <Row justify="space-evenly">
        <Col xs={24} sm={8} xl={4}>
          <Title level={5}>Endereço</Title>
          <Paragraph>Av.Pres.Vargas - Centro,</Paragraph>
          <Paragraph>Douradina-MS, 79880-000</Paragraph>
        </Col>
        <Col xs={24} sm={8} xl={4}>
          <Title level={5}>Contato</Title>
          <Paragraph>Vendas: (67) 9 9642-3189</Paragraph>
          <Paragraph>Financeiro: (67) 9 9942-4289</Paragraph>
        </Col>
        <Col xs={24} sm={8} xl={4}>
          <Title level={5}>Redes Sociais</Title>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.icon}
                  title={<a style={{textDecoration: "none"}} href={item.link}>{item.title}</a>}
                />
              </List.Item>
            )}
          
          
          />
        </Col>
      </Row>
      
      <Divider />
      
      <Row justify="space-evenly">
        <Col>
          <Paragraph>© 2024 - Metalurgica Tavares</Paragraph>
        </Col>
      </Row>
    </>
  )
}

export default NavFooter