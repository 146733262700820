import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getEstoque = async (page, search = '') => {
    const { data } = await api.get(`/estoque?page=${page}&search=${search}`);
    return data;
};

export const getEstoquePdf = async () => {
    const response = await api.get(`/estoque_all`, {
        responseType: 'blob'  // Ensure the response is handled as binary data
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
};

export const getEstoqueById = async (id) => {
    const { data } = await api.get(`/estoque/${id}`);
    return data;
};

export const createEstoque = async (data) =>
    await api.post('/estoque', data);

export const editEstoque = async (id, data) =>
    await api.put(`/estoque/${id}`, data);

export const deleteEstoque = async (id) =>
    await api.delete(`/estoque/${id}`);