import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getDepartamento = async (page, search = '') => {
    const { data } = await api.get(`/departamento?page=${page}&search=${search}`);
    return data;
};

export const getDepartamentoAll = async () => {
    const departemento = []
    const { data } = await api.get(`/departamento_all`);
    
    for (const item of data.data) {
        item.value = item.id
        item.label = item.nome
        departemento.push(item)
    }
    
    return departemento;
};

export const getDepartamentoById = async (id) => {
    const { data } = await api.get(`/departamento/${id}`);
    return data;
};

export const createDepartamento = async (data) =>
    await api.post('/departamento', data);

export const editDepartamento = async (id, data) =>
    await api.put(`/departamento/${id}`, data);

export const deleteDepartamento = async (id) =>
    await api.delete(`/departamento/${id}`);