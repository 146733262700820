import useAxios from '../../../utils/useAxios';
const api = useAxios();

export const getEscolaridade = async (page, search = '') => {
    const { data } = await api.get(`/escolaridade?page=${page}&search=${search}`);
    return data;
};

export const getEscolaridadeAll = async () => {
    const escolaridade = []
    const { data } = await api.get(`/escolaridade_all`);

    for (const item of data.data) {
        item.value = item.id
        item.label = item.descricao
        escolaridade.push(item)
    }

    return escolaridade;
};

export const getEscolaridadeById = async (id) => {
    const { data } = await api.get(`/escolaridade/${id}`);
    return data;
};

export const createEscolaridade = async (data) =>
    await api.post('/escolaridade', data);

export const editEscolaridade = async (id, data) =>
    await api.put(`/escolaridade/${id}`, data);

export const deleteEscolaridade = async (id) =>
    await api.delete(`/escolaridade/${id}`);